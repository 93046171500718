import axios from "axios";
import { computed } from "vue";
export const ActivateImpersonateUser = {
  data: () => ({}),
  mounted() {},
  computed: {},
  methods: {
    async ActivateImpersonateUserMethod(user_details) {
      try {
        let data = JSON.stringify({
          user_email_id: user_details.user_email_id,
          bank_id: user_details.bank_id,
          bank_name: user_details.bank_name,
          role_id: user_details.role_id,
          executive_first_name: user_details.executive_first_name,
          executive_last_name: user_details.executive_last_name,
          executive_email_id: user_details.executive_email_id,
          password: user_details.user_confirm_password,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${this.GetDynamicURL()}activateImpersonateUser`,
          headers: {
            "Content-Type": "application/json",
            "x-api-key": this.GetDynamicURLAPIKey(),
          },
          data: data,
        };
        let result = await axios(config);
        return result;
      } catch (error) {
        console.log("Err", error);
      }
    },
    GetDynamicURL() {
      return window.location.hostname === "dev.bankxchange.com"
        ? "https://6cvpo8vzci.execute-api.us-east-1.amazonaws.com/dev/"
        : window.location.hostname === "test.bankxchange.com"
        ? "https://r8x3g0xmm8.execute-api.us-east-1.amazonaws.com/test/"
        : window.location.hostname === "uat.bankxchange.com"
        ? "https://ioy2zglt8l.execute-api.us-east-1.amazonaws.com/uat/"
        : "https://ud016usm3m.execute-api.us-east-1.amazonaws.com/live/";
    },
    GetDynamicURLAPIKey() {
      return window.location.hostname === "dev.bankxchange.com"
        ? "bMrmIdV3z3HVEaN6TycW5uWZ7uIMJ8laKzTtUepd"
        : window.location.hostname === "test.bankxchange.com"
        ? "RWeq9qTEI97Gh0JehvHu76Jqk9gX8MlY483LEmN9"
        : window.location.hostname === "uat.bankxchange.com"
        ? "RD85tixVzc9jzpZKdXgpb2qZVDFiVazf6mw1hLzV"
        : "X5nUVeHPtT2qT2Oxw7hEJ1PmWn8n1QYNamhGet3T";
    },
  },
};
